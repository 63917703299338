"use strict";

/*global
 window, document, console, require, Foundation
 */

import WaveSurfer from "wavesurfer.js";

document.addEventListener('DOMContentLoaded', () => {
    //$(document).foundation();

    const form = $('#contact-form');
    let contactId = false;

    $('.continue-btn').click(function(){
        const $hide = $('#'+ $(this).data('hide') );
        const $show = $('#'+ $(this).data('show') );

        if ($(this).data('hide') === 'intro') {
            setAudio();
        }

        $hide.fadeOut(function(){
            $show.fadeIn();
            $('body').attr('data-page', $(this).data('show'));

            $('html, body').animate({
                scrollTop: $show.offset().top
            }, 300);
        });
    });

    form.on('change', 'input[type="text"], input[type="email"]', function(){
        $(this).parent().removeClass('error');
    });

    $('body').on('click', '.open-rules', function(){
        $('.rules').slideToggle(200);

        $('html, body').animate({
            scrollTop: $('.rules').offset().top
        }, 300);
    });

    $('body').on('click', '.close-rules', function(){
        $('.rules').slideUp(200);
    });

    form.on('submit', function(e) {
        e.preventDefault();
        $('input[type="submit"]').attr('disabled', true);

        var valid = form.validateForm({
            nimi: {
                required: '',
            },
            email: {
                required: '',
                email: ''
            }
        });

        if(valid){
            $('.form-error').hide();
            sendFormViaAjax(form);
        } else {
            $('.form-error').fadeIn(200);
            $('input[type="submit"]').attr('disabled', false);
            $('html, body').animate({
                scrollTop: $('form .error').offset().top - 80
            }, 300);
        }
    });

    function sendFormViaAjax() {
        $.ajax({
            url: '/php/submit.php',
            type: 'POST',
            cache: 'false',
            data: form.serialize()
        }).always(function (data) {
            contactId = data;

            $('#form').fadeOut(function(){
                $('#quiz').fadeIn();
                $('body').attr('data-page', 'quiz');

                $('html, body').animate({
                    scrollTop: $('#quiz').offset().top
                }, 300);
            });
        });
    }

    let waves = {
        '1-a': '',
        '1-b': '',
        '2-a': '',
        '2-b': '',
        '3-a': '',
        '3-b': '',
        '4-a': '',
        '4-b': '',
        '5-a': '',
        '5-b': '',
        '6-a': '',
        '6-b': '',
        '7-a': '',
        '7-b': '',
        '8-a': '',
        '9-a': '',
        '10-a': '',
        '11-a': '',
        '12-a': ''
    };

    let choices = {};

    function setAudio(){
        $.each(waves, function(key, value){
            waves[key] = WaveSurfer.create({
                container: '.waveform[data-id="'+ key +'"]',
                waveColor: '#979797',
                progressColor: '#00FF00',
                barWidth: 1,
                barHeight: 3,
                cursorWidth: 0,
                barGap: 4,
                height: 30,
                interact: false
            });

            waves[key].on('finish', function(){
                $('.play[data-id="'+ key +'"]').removeClass('playing');
                $('.play[data-id="'+ key +'"]').parent().find('p').removeClass('hidden');
                $('.waveform[data-id="'+ key +'"]').css('opacity', 0);
                waves[key].stop();
            });
        });
    }

    $('body').on('click', '.play', function () {
        $(this).toggleClass('playing');

        const parent = $(this).parents('.sound');
        const key = $(this).data('id');
        const $wave = $('.waveform[data-id="'+ key +'"]');

        if (!$wave.hasClass('loaded')) {
            waves[key].load('audio/' + key + '.mp3');
            $wave.addClass('loaded')

            waves[key].on('ready', function () {
                parent.find('.waveform').show().css('opacity', 1);
                waves[key].play();
                parent.find('p').addClass('hidden');
            });
        } else {
            parent.find('p').addClass('hidden');
            $wave.css('opacity', 1);
            waves[key].playPause();
        }
    });

    $('body').on('click', '.choose-btn:not(.disabled)', function(){
        const button = $(this);
        const parent = $(this).parents('.question');
        let id = parent.data('question');

        if (id === 'break') {
            parent.fadeOut(function(){
                id = 9;

                $('.question[data-question="'+ id +'"] p').removeClass('hidden');
                $('.question[data-question="'+ id +'"] .waveform').css('opacity', 0);
                $('.question[data-question="'+ id +'"] .play').removeClass('playing');

                $('.question[data-question="'+ id +'"]').fadeIn();
            });
            return;
        }
        button.addClass('disabled');

        choices[id] = $(this).data('answer');

        parent.find('.selected').removeClass('selected');
        $(this).addClass('selected');

        parent.fadeOut(function(){
            button.removeClass('disabled');

            waves[id +'-a'].stop();

            if (id < 8) {
                waves[id +'-b'].stop();
            } else if (id === 12) {
                getResult();
                return;
            }

            if (id === 8) {
                $('.question[data-question="break"]').fadeIn();
                return;
            }

            id = id + 1;

            $('.question[data-question="'+ id +'"] p').removeClass('hidden');
            $('.question[data-question="'+ id +'"] .waveform').css('opacity', 0);
            $('.question[data-question="'+ id +'"] .play').removeClass('playing');

            $('.question[data-question="'+ id +'"]').fadeIn();
        });
    });

    $('body').on('click', '.back-btn', function(){
        const parent = $(this).parents('.question');
        let id = parent.data('question');

        if (id === 'break') {
            parent.fadeOut(function(){
                $('.question[data-question="8"] p').removeClass('hidden');
                $('.question[data-question="8"] .waveform').css('opacity', 0);
                $('.question[data-question="8"] .play').removeClass('playing');
                $('.question[data-question="8"]').fadeIn();
            });
            return;
        }

        parent.fadeOut(function(){
            waves[id +'-a'].stop();

            if (id === 9) {
                $('.question[data-question="break"]').fadeIn();
                return;
            }

            if (id < 8) {
                waves[id +'-b'].stop();
            }

            id = id - 1;

            $('.question[data-question="'+ id +'"] p').removeClass('hidden');
            $('.question[data-question="'+ id +'"] .waveform').css('opacity', 0);
            $('.question[data-question="'+ id +'"] .play').removeClass('playing');

            $('.question[data-question="'+ id +'"]').fadeIn();
        });
    });

    $('body').on('click', '.reload-btn', function(){
        location.reload();
    });

    function getResult() {
        if (contactId) {
            choices['yhteystiedot'] = contactId;
        }

        $.ajax({
            url: '/php/result.php',
            type: 'POST',
            cache: 'false',
            data: choices
        }).always(function (data) {
            data = $.parseJSON(data);

            $('.result-count span').text(data.count);
            $('.result-title').html(data.title);
            $('.result-text').html(data.text);
            $('#quiz').hide();
            $('#result').fadeIn();
        });
    }
});

import './form-validator';